* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --cta: #FDCF02;
  --padding: 80px;
  --cursiveFont: 'Allura', cursive;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-smooth: antialiased;
  /* react select is adding right padding to the body when menu is in a right drawer. Forcefully prevent this. */
  padding: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  font-family: "Nanum Gothic", sans-serif;
  overflow-x: hidden;
  background-color: #E9E8E6;
  position: fixed;
}

p {
  line-height: 1.35;
}

li {
  margin-top: 5px;
}


.App {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  cursor: pointer;
  padding: 5px;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.close-button:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.nav-menu {
  position: fixed;
  left: calc(100% + 10px);
  bottom: 0;
  top: 0;
  z-index: 100;
  visibility: hidden;
  background-color: #FFFFFF;
  display: flex;
  transition: all 0.6s cubic-bezier(.25, .8, .25, 1);
  overflow: hidden;
}

.nav-menu.--open {
  left: 0;
  right: 0;
  /* width: 100%; */
  /* height: 100%; */
  visibility: visible;
}

.menu-container {
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;
  width: 100vw;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  background-color: var(--cta);
  padding-top: 50px;
  justify-content: center;
  padding-right: 50px;
  align-items: flex-end;
  padding-right: 50px;
  align-items: flex-end;

}

/* .menu div {
  flex: 1;
} */

.dropdown-menu {
  position: absolute;
  width: fit-content;
  min-width: 250px;
  background-color: white;
  left: 0;
  top: calc(100% + 10px);
  padding: 10px;
}

.nav-link {
  text-decoration: none;
  color: #000000;
}

.nav-link.active {
  color: #20ABAD;
  font-weight: 700;
}

.nav-link.active.--contact,
.nav-link.active.--about {
  font-weight: 500;
  color: #000000;
}

.menu-item {
  font-size: 50px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: 'Playfair Display', sans-serif;
}

.dropdown-menu-item {
  margin-top: 8px;
}

.menu-item-text:hover {
  font-weight: 700;
}

.nav-link.active:hover {
  font-weight: 700;
}

.menu-item-text.--expanded {
  font-weight: 700;
}

.menu-item-text.--active {
  color: #20ABAD;
  font-weight: 700;
}

.menu-photo {
  background-color: #FFFFFF;
  background: left / cover no-repeat url("./imgs/menu-photo.webp");
  position: relative;
  flex: 1;
  /* flex: 1 1 0%; */
  /* height: 100%; */
  overflow: hidden;
}

.menu-photo-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: var(--padding);
}

.menu-photo-backdrop h2 {
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.menu-photo-backdrop h2:last-child {
  align-self: flex-end;
}


.button {
  max-width: 300px;
  border-radius: 25px;
  min-width: 100px;
  padding: 15px;
  background-color: var(--cta);
  color: #000000;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.button.--secondary {
  background-color: #FAFAFA;
}

.button:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.button.--disabled {
  cursor: not-allowed;
  filter: brightness(0.5);
  box-shadow: none;
}

.section {
  min-height: 500px;
  padding-right: var(--padding);
  padding-left: var(--padding);
}

.section.--morePadding {
  padding-top: 25px;
  padding-bottom: 25px;
}

.get-app-bar {
  display: flex;
  padding: 10px var(--padding);
  background-color: var(--cta);
  min-height: 80px;
  align-items: center;
}

.get-app-bar h3 {
  font-size: 30px;
}

.appstore-button-container {
  width: 405px;
  justify-content: space-between;
  min-width: 405px;
  display: flex;
}

@media (max-width: 790px) {
  .get-app-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .get-app-bar h3 {
    margin-bottom: 25px;
    margin-right: 0px !important;
  }
}

.social-button {
  cursor: pointer;
  border-radius: 38px;
  height: 38px;
  width: 38px;
  margin-right: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.social-button:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}


.hero-section {
  background: center / cover no-repeat url("./imgs/new/hero.webp");
  width: 100%;
  background-position: bottom right;
  height: calc(100vh - 60px);
  max-height: 700px;
  padding: 25px var(--padding);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 50px;
  position: relative;
}

.hero-underlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: right no-repeat url("./imgs/new/steph-hero.png");
  z-index: 1;
}

.hero-under-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 5;
}

.name-header-container {
  width: fit-content;
  z-index: 20;
  text-align: center;
}

.call-to-actions {
  z-index: 20;
}

.name-header-container h2 {
  color: #20ABAD;
  font-weight: bolder;
  font-size: 46px;
  font-family: 'Playfair Display', sans-serif;
}

.name-header-container h4 {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  max-width: 500px;
}

.tag-and-buttons {
  width: 350px;
  /* margin-top: auto; */
  margin-bottom: 60px;
  padding-left: 50px;
}

.right-this-way {
  max-width: 450px;
}

.right-this-way h4,
.right-this-way h3 {
  font-size: 45px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
}

.about-me-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
}

.about-me-section h4 {
  font-size: 45px;
  font-weight: 500;
  text-shadow: 1px 1px 2px #9c9c9c;
  margin-bottom: 25px;
}

.photo-and-description {
  display: flex;
  /* flex-wrap: wrap; */
}

.hi-there-profile {
  display: flex;
  flex-direction: column;
}

.hi-there-profile h2 {
  font-size: 38px;
  color: #20ABAD;
  text-align: center;
  font-family: var(--cursiveFont);
  font-weight: normal;
}

.hi-there-profile h3 {
  font-size: 21px;
  color: #20ABAD;
  text-align: center;
  font-weight: 400;
}

.hi-there-profile .bold {
  font-weight: bold;
}

.photo-and-description .photo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-text {
  min-width: 320px;
}

.welcome-text p {
  margin-bottom: 15px;
}

.meet-testimonials-section {
  background-color: #FCF8F5;
  min-height: 350px;
}

.meet-testimonials-section h4 {
  color: #20ABAD;
}

.monials-grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, 250px);
  grid-auto-rows: minmax(50px, auto);
  justify-content: center;
}


.monial-cell {
  text-align: center;
  color: #000000;
  font-size: 18px;
}

.better-way-section {
  background-color: #b9c9e5;
  height: 600px;
  display: flex;
  align-items: center;
  background: left center / cover no-repeat url("./imgs/arms-out-girl.webp");
}

.better-way-statement {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  width: 350px;
}

.better-way-statement h3 {
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.better-way-statement p {
  font-size: 24px;
}

.choose-concierge-section {
  min-height: 600px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  overflow-x: hidden;
  /* flex-wrap: wrap; */
}

.choose-concierge-section h4 {
  font-size: 40px;
  max-width: 400px;
  min-width: 100px;
  margin-bottom: 25px;
  text-align: center;
}

.choose-app,
.choose-therapy {
  width: 300px;
  min-width: 300px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}

.choose-app p {
  margin-bottom: 10px;
}

.choose-app-text-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.choose-app-text-container .button {
  margin-top: auto;
}

.choose-therapy {
  width: 500px;
  min-width: 300px;
}

.choose-therapy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-app div h4,
.choose-therapy div h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
}

.choose-app div p,
.choose-therapy div p {
  text-align: center;
}

.first-and-only-app-section {
  display: flex;
  background-color: var(--cta);
  min-height: 80px;
  align-items: center;
}

.first-and-only-app-section h3 {
  font-size: 30px;
}

.first-and-only-section {
  display: flex;
  align-items: center;
  height: 800px;
  background: center / cover no-repeat url("./imgs/older-woman-laptop.webp");
}

.first-and-only-text-and-stuff {
  max-width: 50%;
  min-width: 300px;
}


.first-and-only-text-and-stuff h4 {
  font-size: 40px;
  color: #FFFFFF;
}

.first-and-only-text-and-stuff span {
  color: var(--cta);
}

.what-people-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, auto);
  grid-auto-rows: minmax(150px, auto);
  justify-content: center;
}

.what-people-cell {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.what-people-text p {
  font-size: 14px;
}

.what-people-image {
  background-color: var(--cta);
  width: 200px;
  height: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.what-people-image img {
  position: absolute;
  width: 100%;
  left: -5px;
  top: -13px;
}

.finding-meaning-section {
  min-height: 600px;
  max-height: 800px;
  background-color: rgb(196, 206, 214);
  position: relative;
  overflow: hidden;
}

.finding-meaning-section video {
  /* max-height: 800px !important; */
}

.finding-meaning-section h4 {
  font-size: 50px;
  font-weight: 700;
  color: #FFFFFF;
  z-index: 2;
}

.finding-meaning-section h3 {
  font-size: 70px;
  font-weight: 700;
  color: #FFFFFF;
  text-shadow: -2px 2px 3px #606060;
}

.subtitle {
  font-size: 30px;
  margin-bottom: 25px;
  font-family: 'Playfair Display', sans-serif;
}

.personalized-section {
  display: flex;
  flex-direction: column;
}

.coaching-section {
  display: flex;
  flex-direction: column;
}

.header-section h4 {
  font-size: 70px;
  color: #FFFFFF;
  text-align: center;
  text-shadow: -1px 1px 3px #7e7d7d;
}

.header-section-subtitle {
  font-size: 30px;
  text-shadow: none;
  font-weight: 500;
  margin-right: 50px;
  margin-bottom: 25px;
}

.insurance-section {
  background-color: #ddd9d8;
  display: flex;
  justify-content: center;
}

.insurance-section h4 {
  text-align: center;
}

.give-gift-section {
  min-height: 600px;
  background: bottom / cover no-repeat url("./imgs/womanonphone.webp");
  background-position-y: 80%;
  position: relative;
}

.contact-section {
  background-color: #EFECEC;

}

.contact-section-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.individual-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(200px, auto));
  grid-auto-rows: minmax(150px, auto);
  justify-content: space-around;
  padding-top: 0px;
  max-width: 1500px;
}

.individual-cell {
  /* width: 380px; */
  display: flex;
  flex-direction: column;
}

.individual-image {
  width: 300px;
  min-width: 300px;
  height: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 4px;
}

.individual-image img {
  width: 100%;
  border-radius: 4px;
  object-position: top;
  object-fit: cover;
}

.individual-text {
  max-width: 300px;
}

.gothic {
  font-family: "Nanum Gothic", sans-serif;
}

.individual-text h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000000;
  text-align: left;
}

.services-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, minmax(300px, auto));
  grid-auto-rows: minmax(300px, auto);
  justify-content: center;
  padding-top: 0px;
}

@media(max-width: 1350px) {
  .services-grid {
    grid-template-columns: repeat(2, minmax(300px, auto));
  }
}

@media(max-width: 650px) {
  .services-grid {
    grid-template-columns: repeat(1, minmax(300px, auto));
  }
}

.header-section {}

.readMore {
  display: flex;
  justify-content: center;
}

.classes {
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  padding: 30px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
}


.contact {
  background-color: #D4D4D4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact form {
  /* margin-top: 30px; */
}

.contact-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact-form-column {
  max-width: 525px;
  min-width: 350px;
  width: 50%;
}


.group {
  position: relative;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}

input,
textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  min-width: 200px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000000;
  background: transparent;
  color: #000000;
  font-family: "Nanum Gothic", sans-serif;
}

input:focus {
  outline: none;
  border-bottom: 1px solid var(--cta);
}

textarea:focus {
  outline: none;
  border-bottom: 1px solid var(--cta);
}


/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--cta);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active state */
input:focus~.bar:before,
input:focus~.bar:after {
  width: 50%;
}

/* active state */
textarea:focus~.bar:before,
textarea:focus~.bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 90%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.3;
}

/* active state */
input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* active state */
textarea:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #a7a3a2;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #b2b1b1;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #888787;
  }

  to {
    width: 0;
    background: transparent;
  }
}


@media (max-width: 1500px) {
  :root {
    --padding: 50px;
  }
}

@media (max-width: 1000px) {
  :root {
    --padding: 25px;
  }
}

@media (max-width: 500px) {
  :root {
    --padding: 15px;
  }
}

@media (min-width: 1500px) {
  :root {
    --padding: 15%;
  }
}

@media (min-width: 3000px) {
  .personalized-section-img {
    width: 25% !important;
  }
}

@media (min-width: 2500px) {
  .personalized-section-img {
    width: 35% !important;
  }
}

@media (max-width: 1800px) {
  .choose-concierge-section .subtitle {
    max-width: unset;
    flex: 1 0 100%;
    width: 100%;
  }

  .personalized-section-img {
    width: 45% !important;
  }
}

@media (min-width: 1500px) {
  .give-gift-section {
    background-position-x: 90%;
    background-position-y: 70%;
  }

  .give-gift-section {
    height: 800px;
  }
}


@media (max-width: 1250px) {
  .what-people-grid {
    grid-template-columns: repeat(3, auto);
    grid-auto-rows: minmax(150px, auto);
  }
}


@media (max-width: 1200px) {
  .choose-concierge-section h3 {
    max-width: unset;
  }

  /* .finding-meaning-section video {
    min-height: 600px;
  } */

  .finding-meaning-section h4 {
    font-size: 40px !important;
  }

  .finding-meaning-section h3 {
    font-size: 60px;
  }

  .contact-form-column {
    width: 45%;
  }
}

@media (max-width: 1100px) {
  .finding-meaning-section video {
    height: 600px !important;
    width: auto !important;
    position: absolute;
    right: 0;
  }

  .insurance-section h4 {
    font-size: 38px;
  }
}


@media (max-width: 790px) {
  .hero-underlay {
    background: right 30% no-repeat url("./imgs/new/steph-hero.png");
  }

  .first-and-only-app-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .first-and-only-app-section h3 {
    margin-bottom: 25px;
    margin-right: 0px !important;
  }

  .appstore-button-container {
    margin-left: 0 !important;
  }

  .tag-and-buttons {
    padding-left: 0;
  }

  .right-this-way {
    padding-right: 90px;
  }

  .right-this-way h4 {
    font-size: 34px;
  }

  .right-this-way h3 {
    font-size: 38px;
  }
}

@media (max-width: 900px) {
  .monials-grid {
    grid-template-columns: repeat(2, 250px);
  }

  .individual-grid {
    grid-template-columns: repeat(2, 300px);
  }

  .insurance-section>div {
    flex-direction: column;
    align-items: center;
  }

  .insurance-section>div>div {
    width: 80% !important;
    max-width: 80% !important;
    margin-bottom: 25px;
  }
}


@media (max-width: 845px) {
  /* .photo-and-description .photo {
    display: flex;
    flex-wrap: wrap;
  } */

  /* .photo-and-description .photo {
    margin-bottom: 25px;
  }
  
  .welcome-text {
    min-width: 320px;
  } */

  /* .about-logos {
    width: 250px !important;
    flex-wrap: wrap;
  }

  .about-logos>img {
    margin-bottom: 25px;
  } */

  .what-people-grid {
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: minmax(150px, auto);
  }

  .finding-meaning-section h4,
  .finding-meaining-section>div>div h4 {
    font-size: 42px !important;
  }

  .finding-meaning-section>div>div {
    max-width: 90% !important;
    /* width: 90% !important; */
  }

  .finding-meaning-section h3 {
    font-size: 50px;
  }

  .header-section {
    height: 250px !important;
  }

  .header-section h4 {
    font-size: 52px;
  }

  .header-section-subtitle {
    font-size: 22px;
  }

  .photo-and-description {
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-photo {
    width: 350px !important;
    height: "auto" !important;
  }
}

@media (max-width: 765px) {
  .contact-form-column {
    width: 100%;
  }

  .contact-section-form {
    margin-top: 30px;
    justify-content: center;
  }

  .contact-form {
    margin-top: 100px;
  }
}

@media (max-width: 750px) {

  .first-and-only-section {
    height: 600px;
  }


  .first-and-only-text-and-stuff h4 {
    font-size: 32px;
  }

  .give-gift-section>div h2 {
    font-size: 28px !important;
  }

  .give-gift-section>div h1 {
    font-size: 38px !important;
  }
}

@media (max-width: 730px) {}

@media (max-width: 700px) {
  .hero-section {
    background-position-x: 80%;
  }

  .individual-grid {
    grid-template-columns: repeat(1, 340px);
  }

  .name-header-container h3,
  .name-header-container h2,
  .name-header-container h4 {
    text-align: left;
  }

  .right-this-way {
    padding-right: 40px;
  }

  .right-this-way h4 {
    font-size: 32px;
    text-align: left;
  }

  .right-this-way h3 {
    font-size: 34px;
    text-align: left;
  }

  .subtitle {
    font-size: 25px !important;
  }

  /* .contact-section  > div {
    width: 80% !important; 
  } */

  .contact-form {
    margin-top: 25px;
  }

  .get-app-bar h3 {
    text-align: center;
  }

  .you-ready-video {
    right: -50% !important;
  }

}

@media (max-width: 650px) {
  .individual-horiz {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .individual-horiz .individual-image {
    margin-right: 0px !important;
    margin-bottom: 25px !important;

  }

  .hero-section {
    background-position-x: 85%;
  }

  .large-menu-item {
    font-size: 20px;
  }

  .dropdown-menu {
    min-width: 230px;
  }


}

@media (max-width: 600px) {
  .menu-photo-backdrop h2 {
    font-size: 42px;
  }

}


@media (max-width: 575px) {
  .monials-grid {
    grid-template-columns: repeat(1, 320px);
  }

  .header-section h4 {
    font-size: 42px;
    text-align: left;
  }

  .header-section-subtitle {
    font-size: 38px;
  }

  .finding-meaning-section h4,
  .finding-meaining-section>div>div h4 {
    font-size: 32px !important;
  }

  .finding-meaning-section h3 {
    font-size: 40px;
  }

  .first-and-only-text-and-stuff h4 {
    font-size: 28px;
  }

  .subtitle {
    /* font-size: 35px !important; */
  }


  .header-section>div {
    justify-content: flex-end !important;
    align-items: flex-start;
  }

  .better-way-statement h3 {
    text-align: left;
  }


  .menu-photo-backdrop h2 {
    font-size: 32px;
  }
}

@media (max-height: 667px) {
  .menu {
    padding-top: 100px;
  }
}

@media (max-width: 490px) {
  .menu-photo {
    display: none;
  }

  .menu-item {
    font-size: 38px;
  }

  .menu {
    width: 100%;
    flex: 1;
  }

  .hero-underlay {
    background: left 30% no-repeat url("./imgs/new/steph-hero.png");
  }
}

@media (max-width: 500px) {
  .you-ready-video {
    right: -50% !important;
  }
}

@media (max-width: 450px) {
  .individual-grid {
    justify-content: flex-start;
  }

  .appstore-button-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .appstore-button-container a {
    width: 300px !important;
  }

  .appstore-button-container a:last-child {
    margin-top: 25px;
  }

  .give-gift-section h2,
  .give-gift-section h1 {
    text-align: left !important;
  }

  .insurance-section>div {
    align-items: flex-start !important;
  }

  .insurance-section>div>div {
    width: 100% !important;
    min-width: 100% !important;
  }

  .get-app-bar h3 {
    font-size: 24px;
  }

  .appstore-button-container {
    flex-wrap: wrap;
    min-width: 190px;
    max-width: 190px;
    /* justify-content: center; */
  }

  .individual-grid {
    justify-content: center !important;
  }

  /* .individual-cell {
    align-items: center;
  } */

  /* .appstore-button-container .app-button:first-child {
    margin-bottom: 25px;
  } */

  .get-app-bar .app-button {}

  .get-app-bar .app-button-text {
    font-size: 15px !important;
  }
}

@media (max-width: 450px) {
  .name-header-container h2 {
    font-size: 36px;
  }

  .name-header-container h4 {
    font-size: 24px;
  }


  .right-this-way h3 {
    font-size: 28px;
  }

  .right-this-way h4 {
    font-size: 28px;
  }

}

@media (max-width: 400px) {
  .name-header-container h2 {
    font-size: 32px;
  }

  .name-header-container h4 {
    font-size: 22px;
  }


  .right-this-way h3 {
    font-size: 26px;
  }

  .right-this-way h4 {
    font-size: 26px;
  }



  .better-way-section {
    align-items: flex-start;
    background-position-x: 30%;
  }

  .what-people-cell {
    width: 365px;

  }

  .what-people-image {
    width: 365px;
    height: 311px;
  }

  .what-people-image img {
    top: -2px;
  }

  .about-logos {
    width: 250px !important;
    flex-wrap: wrap;
  }

  .about-logos>img {
    margin-bottom: 25px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 110px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  z-index: 200;
  padding: 15px;
  transition: height 0.3s cubic-bezier(.25, .8, .25, 1);
  overflow-anchor: none;
  overflow: visible;
}

.--shadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 60px;
}

.logo-icon {
  top: 10px;
  height: 90px;
  position: absolute;
  left: 15px;
  transition: height 0.3s cubic-bezier(.25, .8, .25, 1);
}

.logo-icon.--small {
  top: 5px;
  height: 50px;
}

.large-nav {
  display: flex;
  width: 500px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.large-menu-item {
  height: 36px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  position: relative;
}

.large-menu-item.--sub-menu {
  font-size: 18px;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .large-nav {
    margin-right: 10px;
  }
}

.circle-divider {
  background-color: #EFECEC;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  min-height: 300px;
  /* max-height: 350px; */
  padding: 25px;
}

.circle-container {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex: 1;
}

.circle-container img {
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 0;
}

.circle-container h3 {
  max-width: 800px;
  margin-bottom: 10px;
  font-size: 32px;
  text-align: center;
  z-index: 1;
  font-weight: 400;
  line-height: 0.9
}

.circle-container h4 {
  font-size: 18px;
  text-align: center;
  max-width: 800px;
  z-index: 1;
  font-weight: normal;
}

.field-vid-section {
  position: relative;
  height: 700px;
  min-height: 400px;
  max-height: 700px;
}

.vid-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding: 40px;
  top: 0;
  z-index: 10;
}

.vid-overlay .left-text {
  max-width: 460px;
  margin-right: 40px;
  line-height: 1;
}

.vid-overlay .left-text h4 {
  font-size: 36px;
  color: #FFFFFF;
  text-shadow: 3px 3px 8px rgba(56, 55, 55, 0.4);
}

.vid-overlay .left-text h2 {
  font-size: 55px;
  color: #FFFFFF;
  font-weight: bolder;
  font-family: 'Playfair Display', sans-serif;
  text-shadow: 3px 3px 8px rgba(56, 55, 55, 0.4);
}

.vid-overlay .right-text h2 {
  font-size: 55px;
  color: #FDCF02;
  font-family: 'Playfair Display', sans-serif;
  text-shadow: 3px 3px 8px rgba(56, 55, 55, 0.4);
  min-width: 300px;
}

.next-gen-section {
  min-height: 250px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: center / cover no-repeat url("./imgs/new/leaves.webp");
}

.next-gen-section h3 {
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  max-width: 600px;
}

.app-icon-container img {
  width: 150px;
}

.social-connect-section {
  height: auto;
  min-height: 300px;
  background-color: #FFFFFF;
  padding-top: 50px;
  padding-bottom: 50px;
}

.social-connect-section h4 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
}

.social-connect-section-feed {
  width: 100%;
  height: 200px;
  border: 2px solid black;
}

.what-is-evaluation {
  background-color: #FCF8F5;
}

.what-is-question {
  display: flex;
  max-width: 300px;
}

.what-is-question-numeral {
  font-size: 50px;
  font-style: italic;
  line-height: 20px;
  padding-top: 20px;
  margin-right: 5px;
}

.what-is-question-text {
  text-align: center;
  font-size: 18px;
}

.what-is-eval-questions-container {
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: auto;
  justify-content: center;
  max-width: 700px;
  gap: 30px;
}

.eval-questions-end-statement {
  margin-top: 30px;
  text-align: center;
}

.who-needs-assessment-section {
  background-color: #FFFFFF;
}

.who-needs-assessment-section p {
  margin-bottom: 20px;
}

.how-assessments-work-section {
  background-color: #FCF8F5;
}

.indent {
  margin-left: 20px;
}

.text-indent {
  text-indent: 20px each-line;
}

.better-way-divider {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: center 70% / cover no-repeat url("./imgs/new/therapy-plant.webp");
}

.better-way-divider-text {
  max-width: 500px;
}

.better-way-divider h3 {
  font-size: 30px;
  font-weight: 700;
  color: #20ABAD;
  text-align: center;
  margin-bottom: 25px;
}

.better-way-divider h4 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.dreamed-divider-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.finding-center-app-last {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #FCF8F5;
}

.finding-center-summary {
  background-color: #FFFFFF;
}

@media (max-width: 650px) {
  .individual-horiz {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .individual-horiz .individual-image {
    margin-right: 0px !important;
    margin-bottom: 25px !important;

  }

  .hero-section {
    background-position-x: 85%;
  }

  .large-nav {
    max-width: 380px;
  }

  .large-menu-item {
    font-size: 20px;
    min-width: 80px;
  }

  .vid-overlay .left-text h4 {
    font-size: 26px;
  }

  .vid-overlay .left-text h2 {
    font-size: 40px;
  }

  .vid-overlay .right-text h2 {
    font-size: 40px;
    min-width: 200px;
  }

  .vid-overlay {
    flex-direction: column;
  }
}


@media (max-width: 450px) {
  .what-people-grid {
    grid-template-columns: repeat(1, auto);
    grid-auto-rows: minmax(150px, auto);
  }

  .what-is-eval-questions-container {
    grid-template-columns: repeat(1, auto);
  }
}

@media (max-width: 850px) {
  .workplace-trainings-section-content img {
    max-width: 300px !important;
  }
}


@media (max-width: 850px) {
  .workplace-trainings-section-content {
    flex-direction: column;
    align-items: center;
  }

  .reverse-flex-column {
    flex-direction: column-reverse;
  }

  .workplace-trainings-section-content div {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .workplace-trainings-section-content img {
    max-width: 400px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}


@media (max-width: 500px) {
  .workplace-trainings-section-content img {
    max-width: 100% !important;
  }
}

.circle-container h3 {
  font-family: var(--cursiveFont);
  font-size: 52px;
}

.circle-container h4 {
  font-size: 22px;
}

.playfair {
  font-family: 'Playfair Display', sans-serif;
}

.breathing {
  font-family: var(--cursiveFont);
}


.mobile-menu-item {
  font-size: 32px;
  margin-bottom: 15px;
}

.no-max-width {
  max-width: unset;
}